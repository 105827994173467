<script>
import ui from '/~/core/ui'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import SectionTabs from '/~rec/components/section/section-tabs.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-events-feed-header',
  components: {
    BaseDatepicker,
    BaseIcon,
    BaseButton,
    SectionTabs,
  },
  props: {
    disableAnimation: {
      type: Boolean,
      default: false,
    },
    isCelebrations: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { features } = useRecProfile()

    return {
      features,
      ui,
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    slideSpeed() {
      return this.disableAnimation ? 0 : undefined
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.$refs.tape.initSwiperAndSlide()
      }
    },
  },
  methods: {
    onDayChanged(newDate) {
      this.$emit('new-date', newDate)
    },
    onMonthChanged(newDate) {
      this.$emit('new-date', newDate)
      this.$refs.tape.slideToActiveDate()
    },
    toggleDays() {
      this.visible = !this.visible
    },
    onCreate() {
      this.$router.push({
        name: 'rec-event-create',
      })
    },
  },
}
</script>

<template>
  <div
    class="sticky z-20 mt-[5px] flex w-full shrink-0 flex-col sm:mt-0 md:mb-5"
  >
    <div
      class="mb-0 hidden w-full items-center justify-between md:mb-6 md:flex"
    >
      <h1
        class="text-bold mb-[15px] truncate text-lg text-eonx-neutral-800 sm:block md:mb-0 md:text-2xl"
      >
        Events Calendar
      </h1>
      <base-button class="w-36 max-w-full" @click="onCreate">
        + Create event
      </base-button>
    </div>

    <div
      class="flex w-full shrink-0 flex-col items-center"
      :class="{
        'justify-between pb-[15px]': ui.desktop,
        'justify-center border-b bg-white': !ui.desktop,
      }"
    >
      <div
        class="hidden w-full grow space-x-[15px] overflow-x-auto md:flex lg:space-x-[30px]"
      >
        <section-tabs look="recroom" class="w-full text-eonx-neutral-800" />
      </div>

      <div class="flex min-h-12 shrink-0 items-center md:ml-auto">
        <base-datepicker
          :value="selectedDate"
          type="month-text"
          @input="onMonthChanged"
        />
        <div
          class="ml-2.5 flex w-[30px] shrink-0 text-primary sm:hidden"
          @click="toggleDays"
        >
          <base-icon
            :svg="`rec/${visible ? 'calendar-filled' : 'calendar-linear'}`"
            size="md"
          />
        </div>
      </div>
    </div>
    <transition name="fade">
      <base-datepicker
        v-show="!ui.mobile || visible"
        ref="tape"
        :date="selectedDate"
        :slide-speed="slideSpeed"
        type="days-tape"
        @change="onDayChanged"
      />
    </transition>
  </div>
</template>
