<script>
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-celebrations-tile',
  components: {
    BaseButton,
    BaseIcon,
    BaseAvatar,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { myRecId, features } = useRecProfile()

    return {
      myRecId,
      features,
      ui,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    statusIcon() {
      const type = this.event.type

      if (type === 'award') {
        return { bg: 'bg-violet-400', icon: 'rec/award-solid' }
      } else if (type === 'birthday') {
        return { bg: 'bg-pink-400', icon: 'rec/cake' }
      } else {
        return { bg: 'bg-amber-300', icon: 'rec/briefcase' }
      }
    },
    abbr() {
      const member = this.event.member

      return `${member.lastName.substr(0, 1)} ${member.firstName.substr(0, 1)}`
    },
    btnSize() {
      return ui.desktop ? 'xl' : 'md'
    },
    showHi5Btn() {
      return this.event?.member?.id !== this.myRecId && this.features.hi5
    },
    eventName() {
      const member = this.event.member
      const eventName = this.event.name

      return eventName.replace(member.firstName + ' ' + member.lastName, '')
    },
    memberFullName() {
      return this.event.member.firstName + ' ' + this.event.member.lastName
    },
  },
  methods: {
    goToHi5s() {
      this.$router.push({
        name: 'rec-hi5-send-to',
        params: {
          sendToId: this.event?.member?.id,
        },
      })
    },
    openProfile() {
      if (this.features.directory) {
        this.$router.replace({
          query: { recProfileId: this.event?.member?.id },
        })
      }
    },
  },
}
</script>

<template>
  <div
    class="mb-5 flex flex-col justify-between overflow-x-auto bg-white px-6 py-5 xs:flex-row sm:rounded-lg"
    :data-id="event.id"
  >
    <div class="flex">
      <div>
        <div class="relative w-24">
          <div class="cursor-pointer" @click="openProfile">
            <base-avatar
              size="3xl"
              :rounded="'xl'"
              :src="event.image"
              :abbr="abbr"
            />
          </div>
          <div
            :class="[
              'absolute -mb-[5px] -mr-2.5 flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white ' +
                'bottom-0 right-0 border-4 border-white',
              statusIcon.bg,
            ]"
          >
            <base-icon :svg="statusIcon.icon" :size="16" />
          </div>
        </div>
      </div>
      <div class="mx-[15px] flex items-center text-eonx-neutral-800 md:mx-5">
        <span class="grid grid-cols-1 break-words">
          <span
            class="font-bold"
            :class="{
              'cursor-pointer': features.directory,
            }"
            @click="openProfile"
          >
            {{ memberFullName }}
          </span>
          {{ eventName }}
        </span>
      </div>
    </div>
    <div
      v-if="showHi5Btn"
      class="flex items-stretch pt-5 lg:items-center lg:pt-0"
    >
      <base-button class="w-full" :size="btnSize" @click="goToHi5s">
        Send Hi-5
      </base-button>
    </div>
  </div>
</template>
